const routes = require('next-routes')

// Name, Page, Pattern 
// Routes must always have name so that we can easily map content type + slug -> url
module.exports = routes()
    .add('agenda', '/agenda', 'agenda')
    .add('agenda-en', '/en/upcoming', 'agenda')
    .add('article', '/artikel/:slug', 'article')
    .add('article-en', '/en/article/:slug', 'article')
    .add('basic', '/over/:slug', 'basic')
    .add('basic-en', '/en/about/:slug', 'basic')
    .add('event', '/evenement/:slug', 'event')
    .add('event-en', '/en/event/:slug', 'event')
    .add('exhibition', '/tentoonstelling/:slug', 'exhibition')
    .add('exhibition-en', '/en/exhibition/:slug', 'exhibition')
    .add('film', '/film/:slug', 'film')
    .add('film-en', '/en/film/:slug', 'film')
    .add('films-archive', '/films/archief', 'films-archive')
    .add('films-archive-en', '/en/films/archive', 'films-archive')    
    .add('films-current', '/films', 'films-current')
    .add('films-current-en', '/en/films', 'films-current')
    .add('home', '/', 'homepage')
    .add('home-en', '/en', 'homepage')
    .add('magazine', '/magazine', 'magazine')
    .add('magazine-en', '/en/magazine', 'magazine')
    .add('person', '/auteur/:slug', 'author')
    .add('person-en', '/en/author/:slug', 'author')
    .add('portal', '/portal/:slug', 'portal')
    .add('portal-en', '/en/portal/:slug', 'portal')
    .add('program', '/programma/:slug', 'program')
    .add('program-en', '/en/program/:slug', 'program')
    .add('programs', '/programmas', 'programs')
    .add('programs-archive', '/programmas/archief', 'programs-archive')
    .add('programs-archive-en', '/en/programs/archive', 'programs-archive')
    .add('programs-en', '/en/programs', 'programs')
    .add('search', '/zoek', 'search')
    .add('search-en', '/en/search', 'search')
    .add('tag', '/tag/:slug', 'program')
    .add('tag-en', '/en/tag/:slug', 'program')
    // Must be at end:
    .add('homepage', '/:slug', 'homepage')
    .add('homepage-en', '/en/:slug', 'homepage')        