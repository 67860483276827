import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from 'rebass'
import Link from 'components/Link'

const Tag = ({ type, translate, invert, children, slug, router, ...rest }) => (
    <Link slug={slug} type={type} router={router}>
        <StyledTag p={3} invert={invert} {...rest}>
            {translate ? translate({ id: "Label." + type }) : children}
        </StyledTag>
    </Link>
);

const StyledTag = styled(Text)`
    display: inline-block;
    text-transform: uppercase;
    background-color: ${props => props.invert ? 'black' : 'white'};
    color: ${props => props.invert ? 'white' : 'black'};
    line-height: 0px;
    padding: 11px 12px;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
    border-radius: 14px;
`

Tag.propTypes = {
    text: PropTypes.string
};

export default Tag;
