import styled from 'styled-components'
import { Box } from 'rebass'
import { maxWidth } from 'styled-system'

const Constrained = styled(Box)`
    ${maxWidth}
    @media (min-width: 1220px) {
        margin: 0 auto;
    }
`

export default Constrained;