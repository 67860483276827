import React from "react";

export default function IconArrowRight({ width = 14, fill = "white", ...rest}) {
    return (
        <svg width={width} viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8.60994 1L12.3994 4.42852M12.3994 4.42852L8.60994 7.85704M12.3994 4.42852H0.399414" stroke={fill} />
        </svg>
    );
}


