import React from 'react'
import styled from 'styled-components'
import { withTheme } from 'styled-components'
   
const StyledButton = styled.button`
  background: "white";
  color: ${props => props.primary ? props.theme.colors.accent : props.theme.colors.black};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-family: ${props => props.theme.fonts.sans};
  font-weight: bold;
  border: 3px solid ${props => props.primary ? props.theme.colors.accent : "black"};
  color: ${props => props.primary};
  line-height: 1.5rem;
  padding: 5px 10px 4px;
  text-transform: lowercase;

  &:hover {
    background: ${props => { return props.primary ? props.theme.colors.accent : props.theme.colors.black} } ;
    color: white;
    cursor: pointer;
  }
`;

const Button = ({children, ...props}) => {
  return (<StyledButton {...props}>{children}</StyledButton>);
};


export default Button;