export function isExternal(urlString) {
    if (!urlString) return false
    return (urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0)
}

// Maps Contentful content type to named route
export function getLinkName(type, lang = 'nl') {
    const suffix = lang === 'nl' ? '' : '-en';
    switch (type) {
        case "Article":
            return 'article' + suffix;
        case "Event":
            return 'event' + suffix;
        case "Exhibition":
            return 'exhibition' + suffix;
        case "Film":
            return 'film' + suffix;
        case "Homepage":
            return 'homepage' + suffix;
        case "Person":
            return 'person' + suffix;
        case "PortalPage":
            return 'portal' + suffix;
        case "Theme":
            return 'tag' + suffix;
        case "Basic":
            return 'basic' + suffix;
        default:
            return 'article' + suffix;
    }
}

export function getContentfulImage(url, params = {}) {
    const defaults = { f: 'faces', fit: 'fill' }
    let serialize = function (obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }
    const o = { ...defaults, ...params };
    return url + '?' + serialize(o)
}

export function responsiveSize(size) {
    const sizes = [200, 450, 800, 1200, 1600, 2000, 2400];
    const index = sizes.findIndex(el => el > size);
    if (index === -1) return sizes[sizes.length - 1];
    return sizes[index];
}

export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }