import React from "react";

export default function IconExternalSitew({ width = 14, fill = "white", ...rest}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" fill="none" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...rest}>
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
            <polyline points="15 3 21 3 21 9" />
            <line x1="10" y1="14" x2="21" y2="3" />
        </svg>
    );
}


