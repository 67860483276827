const fontSizes = [
    12, 14, 16, 20, 26, 32, 40, 64
];

const fontWeights = {
    normal: 500,
    bold: 700
}  

const fontFamily = '"EyeSchulbuch", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif"';

const colors = {
    accent: '#ff0006',
    black: '#000000',
    greyDarkest: '#222222',
    greyDark: '#565656',
    greyMedium: '#747474',
    grey: '#D4D4D4',
    greyLight: '#EAEAEA',
    greyLightest: '#F7F7F7',
    purple: '#6E0792',
    rose: '#FF006B',
    text: '#111111',
    white: '#ffffff',
    feedback: {
        'ok': '#4CCF0E',
        'warning': '#EF8202',
        'error': '#D20000',
        'unknown': '#909090'
    }
};

// Might want to reuse the color definitions above
const cards = {
    Purple: {
        background: '#420CAA',
        highlight: '#FF39B8',
        text: "#82ECFF",
        link: "#ffffff"
    },
    Pink: {
        background: '#FF39B8',
        highlight: '#E24425',
        text: "#ffffff",
        link: "#ffffff",
    },
    Yellow: {
        background: '#FFE02C',
        highlight: '#E24425',
        text: "#000000",
        link: "#FF39B8",
    },
    Green: {
        background: '#6DEB75',
        text: '#420CAA',
        highlight: '#82ECFF',
        link: "#FF39B8",
    },
    Teal: {
        background: '#82ECFF',
        text: '#420CAA',
        highlight: '#6DEB75',
        link: "#FF39B8",
    }
}

const space = [
    0, 4, 8, 16, 32, 64, 128, 256
];


const breakpoints = [
    '600px', '1200px', '1600px'
]
const lineHeights = [
    1, 1.125, 1.25, 1.5, 2
]

// border-radius
const radii = [
    0, 2, 4, 8
]

const borders = [
    0, '1px solid', '2px solid'
]

const shadows = [
    `0 1px 2px 0 ${colors.text}`,
    `0 1px 4px 0 ${colors.text}`
]

// Used?
const buttons = {
    primary: {
        color: 'white',
        backgroundColor: '#222',
    },
    outline: {
        color: '#222',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 2px'
    }
}

// Used?
const Text = {
    fontFamily: fontFamily
}

// Used?
const fonts = {
    sans: fontFamily,
    mono: 'Menlo, monospace',
};

const theme = {
    borders,
    breakpoints,
    cards,
    colors,
    fontFamily,
    fontSizes,
    fontWeights,
    fonts,
    lineHeights,
    radii,
    shadows,
    space,
}

export default theme