import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { Text } from 'rebass'
import Link from './'

const LinkAsButton = ({ internalLink, externalLink, children, router, slug, color = "#82ECFF" }) => {
    
    return (
        <Link {...{ internalLink, externalLink, router, slug }}>
            <StyledLink fontSize={1} color={color} py={2} px={3}>
                {children}
            </StyledLink>
        </Link>
    )
}

const StyledLink = styled(Text)`
    text-decoration: none;
    display: inline-block;
    color: black;
    background-color: ${props => props.color};
    &:hover {
        background-color: white;
    }
`

export default withRouter(LinkAsButton)