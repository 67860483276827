import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { Text } from 'rebass'
import Link from './'
import { IconArrowRight, IconExternalSite } from '../Atoms/index'
import { isExternal } from '../../lib/utils'

const LinkWithArrow = ({ internalLink, externalLink, children, type, router, slug, color = "black" }) => {
    const isExternalLink = !internalLink && (externalLink || isExternal(slug))
    const Icon = isExternalLink ?
        <IconExternalSite fill={color} style={{ paddingLeft: 10 }} /> : 
        <IconArrowRight fill={color} style={{ paddingLeft: 10 }} />
    return (
        <Link {...{ internalLink, externalLink, router, slug, type }}>
            <StyledLink fontSize={1} color={color} external={isExternalLink}>
                {children}
                {Icon}
            </StyledLink>
        </Link>
    )
}

const StyledLink = styled(Text)`
    text-decoration: none;
    border-bottom: solid 1px ${props => props.color};
    display: inline-block;
    padding-bottom: 2px;
    line-height: 100%;
    svg {
        transform: translate(-3px, 0);
    }
    &:hover {
        opacity: 0.95;
        svg {
            transform: ${props => props.external ? 'translate(2px, -2px)' : 'translate(2px, 0)'};
            transition: transform 250ms cubic-bezier(0, .52, 0, 1);
        }
    }
`

export default withRouter(LinkWithArrow)