import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { getContentfulImage } from 'lib/utils';

// TODO: use imgsrc, lazyload images
// TODO: alt tags
const Image = ({ imageOptions, src, attribution, width = 'auto', height = 'auto' }) => (
    <div style={{ position: 'relative' }}>
        <Credit fontSize={0}>{attribution}</Credit>
        <img src={getContentfulImage(src, imageOptions)} alt={attribution} style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
)

const Credit = styled(Text)`
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
`

export default Image
