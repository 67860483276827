import React from 'react'
import { ContextLocale } from "components/ContextLocale"

const withLocale = (WrappedComponent) => (props) => { 
    return (
        <ContextLocale.Consumer>
            {(context) => (
                <WrappedComponent {...props} locale={context.lang} localeContext={context} />
            )}
        </ContextLocale.Consumer>
    )
}

export default withLocale