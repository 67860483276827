import React from "react";
import styled from "styled-components";
import { Pulse }from "styled-spinkit"

const Loader = ({inline}) => (
  <StyledPulse size={inline ? 16 : 50} />
);

const StyledPulse = styled(Pulse)`
  position: absolute;
  left: 50px;
`

export default Loader