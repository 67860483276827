import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTheme } from 'styled-components'

const Heading = ({ as = 'h2', size, children }) => {
    const Header = StyledHeadline[size ? size : as];
    return (<Header as={as}>{children}</Header>);
};

const Headline = styled.h1`
    font-family: ${props => props.theme.fonts.sans};
    font-weight: bold;
`

const HeadlineInBody = styled(Headline)`
    margin-top: 1.5em;
`

const StyledHeadline = {
    'h1': styled(Headline)`
        font-size: ${props => props.theme.fontSizes[6]}px;
    `,
    'h2': styled(HeadlineInBody)`
        font-size: ${props => props.theme.fontSizes[5]}px;
    `,
    'h3': styled(HeadlineInBody)`
        font-size: ${props => props.theme.fontSizes[4]}px;
    `,
    'h4': styled(HeadlineInBody)`
        font-size: ${props => props.theme.fontSizes[3]}px;
    `,
    'h5': styled(HeadlineInBody)`
        font-size: ${props => props.theme.fontSizes[2]}px;
    `,
    'h6': styled(HeadlineInBody)`
        font-size: ${props => props.theme.fontSizes[1]}px;
    `
}


Heading.propTypes = {
    /** Tag to use, like "h1" */
    as: PropTypes.string,
}

export default withTheme(Heading);
