import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage, FormattedDate } from 'react-intl';
import TextTruncate from 'react-text-truncate';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import { Tag } from 'components/Atoms/index';
import Link, {LinkWithArrow} from 'components/Link';
import withLocale from 'hoc/withLocale';
import { responsiveSize } from 'lib/utils';
import Image from 'components/Image'

const messages = defineMessages({
    readmore: {
        id: 'UI.readmore',
        defaultMessage: 'Read more'
    },
    writtenby: {
        id: 'UI.article.by',
        defaultMessage: 'By'
    }
})

const getSubtitle = ({ category, publicationDate, author }) => {
    switch (category) {
        case 'News':
            return publicationDate ? (
                <strong><FormattedDate value={new Date(publicationDate)} month="long" day="numeric" year="numeric" /></strong>
            ) : null
        case 'Interview':
        case 'Column':
        case 'Tips':
        case 'Story':
        default:
            return author ? (
                <span>
                    <FormattedMessage {...messages.writtenby} />&nbsp;<strong>{author.name}</strong>
                </span>
            ) : null
    }
}


class CardEditorialSmall extends React.PureComponent {

    render() {
        const {
            author,
            category,
            image,
            imageAttribution,
            internalLink,
            externalLink,
            publicationDate,
            router,
            slug,
            tag,
            teaser,
            title,
            type,
        } = this.props;

        const imageOptions = {
            w: responsiveSize(this.props.width),
            h: 197
        }

        // Subtitle is based on content type + category
        const subtitle = getSubtitle({ category, publicationDate, author })
        const linkData = { slug, type, router, internalLink, externalLink }

        return (
            <CardContainer>
                {image ? (
                    <Link {...linkData}>
                        <Image imageOptions={imageOptions} imageAttribution={imageAttribution} src={image} />
                    </Link>
                ) : null}
                <Box>

                    {tag ? (
                        <Box my={3}>
                            <Tag invert type={tag.type} mr={3} mb slug={tag.slug} router={router}>{tag.title}</Tag>
                        </Box>
                    ) : null}

                    {subtitle ? (
                        <SubText fontSize={1} color="#9a9a9a" my={3}>{subtitle}</SubText>
                    ) : null}

                    <Box my={3}>
                        <Link {...linkData} style={{ textDecoration: "none" }}>
                            <CardTitle fontSize={3} color="black" my={0}>{title}</CardTitle>
                        </Link>
                    </Box>

                    {teaser ? (
                        <Text fontSize={2} lineHeight={"125%"}>
                            <TextTruncate line={3} text={teaser} />
                        </Text>
                    ) : null}


                    <Box mt={3}>
                        <LinkWithArrow {...linkData}>
                            <FormattedMessage {...messages.readmore} />
                        </LinkWithArrow>
                    </Box>

                </Box>
            </CardContainer>
        )
    }

}

const CardContainer = styled.div`    
    position: relative;

    .card-readmore {
        text-decoration: none;
        border-bottom: solid 1px black;
        display: inline-block;
        padding-bottom: 2px;
        line-height: 100%;
        svg {
            transform: translateX(-3px);
        }
        &:hover {
            opacity: 0.95;
            svg {
                transform: translateX(2px);
                transition: transform 250ms cubic-bezier(0, .52, 0, 1);
            }
        }
    }

`;

const SubText = styled(Text)`
    text-transform: uppercase;
`

const CardTitle = styled(Text)`
    display: block;
    font-weight: bold;
    line-height: 120%;
    text-decoration: none;
`

CardEditorialSmall.propTypes = {
    image: PropTypes.string,
    locale: PropTypes.string,
    router: PropTypes.object,
    teaser: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default withLocale(CardEditorialSmall)