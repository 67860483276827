// Defines global styling, and used as wrapper to pass styled-component theme
// to all children. 

import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import EyeTheme from './theme'

// Fonts
function fontFace(name, src, fontWeight = 'normal', fontStyle = 'normal') {
  return `
    @font-face{
        font-family: "${name}";
        src: url(${require('./fonts/' + src + '.eot')});
        src: url(${require('./fonts/' + src + '.eot')}?#iefix) format("embedded-opentype"),
             url(${require('./fonts/' + src + '.woff')}) format("woff"),
             url(${require('./fonts/' + src + '.ttf')}) format("truetype");

        font-style: ${fontStyle};
        font-weight: ${fontWeight};
    }
`
}
const FontNormal = createGlobalStyle([fontFace('EyeSchulbuch', 'eyeschulbuch-normal')]);
const FontBold = createGlobalStyle([fontFace('EyeSchulbuch', 'eyeschulbuch-bold', 'bold', 'bold')]);
const FontSerif = createGlobalStyle(`
  body {
    @import url('https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i');
  }
`)

// Global styles.
// Yes, verbose. But defining it this way enforces some explanation for global styling, which 
// should generally be avoided.
const ScreenshotStyle = createGlobalStyle`
  img.screenshot {
    max-width: 500px;
    margin-left: 50px;
    border: dashed #666 2px;
    height: auto;
  }
`;
const ContainImages = createGlobalStyle`
  img {
    max-width: 100%;
    height: auto;
  }
`
const StyleResets = createGlobalStyle`
body {
  padding: 0;
  margin: 0;
  font-family: "EyeSchulbuch", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif";
}
`

const StyleWrapper = ({ children }) => (
  <ThemeProvider theme={EyeTheme}>
    <React.Fragment>
      <StyleResets />
      <FontBold />
      <FontNormal />
      <FontSerif />
      <ScreenshotStyle />
      <ContainImages />
      { children }
    </React.Fragment>
  </ThemeProvider>
);

export default StyleWrapper;