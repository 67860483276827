import React from 'react'
import CardEditorialSmall from './small'
import CardEditorialMedium from './medium';
import CardEditorialLarge from './large';

const CardEditorial = ( props ) => {
    switch (props.size) {
        case 'L':
            return <CardEditorialLarge {...props} />
        case 'M':
            return <CardEditorialMedium {...props} />
        case 'S':
        default:
            return <CardEditorialSmall {...props} />
    }
}

export default CardEditorial