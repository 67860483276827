import PropTypes from 'prop-types';
import React from 'react';
import { getLinkName, isExternal } from '../../lib/utils';
import { Link as NextRouterLink } from '../../routes';
import withLocale from 'hoc/withLocale'

class EyeLink extends React.PureComponent {

    static propTypes = {
        internalLink: PropTypes.object,
        externalLink: PropTypes.string,
        locale: PropTypes.string,
        router: PropTypes.object,
        slug: PropTypes.string,
    }

    constructor(props) {
        super(props);
        
        this.handler = (e) => {
            e.preventDefault();
            const path = e.target.getAttribute('href');
            if (isExternal(path)) {
                window.location = path;
            } else {
                this.props.router.push(path);
            }
        }
    }

    render() {
        const { router, children, slug, internalLink, externalLink, type, locale, localeContext, ...rest } = this.props;
        
        if (!router) {
            // Graceful degradation to simple link, used for component testing in a sandbox.
            console.log('no router', router)
            return <a href={slug} {...rest}>{children}</a>
        }

        // For links inside the application.
        // Determine based on content type where possible.
        if (internalLink) {
            // Link content type, referencing internal content
            return (
                <NextRouterLink
                    route={getLinkName(internalLink.__typename, locale)}
                    params={{ 'slug': internalLink.slug }}>
                    <a {...rest}>{children}</a>
                </NextRouterLink>
            )
            //return <a href={slug} onClick={this.handler} className={className}>{children}</a>
        } 
        else if (externalLink) {
            return (
                <NextRouterLink href={externalLink || slug}><a {...rest}>{children}</a></NextRouterLink>
            )    
        }
        else if (slug) {
            // Content type has its own slug
            return (
                <NextRouterLink route={getLinkName(type, locale)}
                            params={{ 'slug': slug }}>
                <a {...rest}>{children}</a>
            </NextRouterLink>
            )
        }        
        return <div {...rest}>{children}</div>
    }

}

export default withLocale(EyeLink)

export { default as LinkWithArrow } from './withArrow';
export { default as LinkButton } from './asButton';