import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import TextTruncate from 'react-text-truncate';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import { IconArrowRight, Tag } from 'components/Atoms/index';
import Link, { LinkWithArrow } from 'components/Link';
import withLocale from 'hoc/withLocale';
import { getContentfulImage, responsiveSize } from 'lib/utils';

const messages = defineMessages({
    readmore: {
        id: 'UI.readmore',
        defaultMessage: 'Read more'
    },
})

class CardEditorialLarge extends React.PureComponent {

    render() {
        const { title, teaser, image, tag, router, slug, type } = this.props;
        const imageOptions = {
            w: responsiveSize(this.props.width),
            h: 427
        }
        const linkData = { slug, type, router }
        return (
            <CardContainer background={image} imageOptions={imageOptions}>
                <ReadabilityGradient />
                <PositionCardText>
                    {tag ? <Tag type={tag.type} mr={3} mb={3} slug={tag.slug} router={router}>{tag.title}</Tag> : null}
                    <Link {...linkData} className="card-editorial-title">{title}</Link>
                    <Text fontSize={3} color="white" lineHeight={"125%"}>
                        <TextTruncate line={3} text={teaser} />
                    </Text>
                    <Box mt={4}>
                        <LinkWithArrow {...linkData} color="white">
                            <FormattedMessage {...messages.readmore} />
                        </LinkWithArrow>
                    </Box>
                </PositionCardText>
            </CardContainer>
        )
    }

}

const PositionCardText = styled.div`
    position: absolute;
    bottom: 60px;
    left: 0;
    margin: ${props => props.theme.space[4]}px;
    margin-bottom: 0;
    margin-left: 95px;
    max-width: 600px;
`

const CardContainer = styled.div`
    min-height: 427px;
    height: 427px;
    background-image: url(${props => getContentfulImage(props.background, props.imageOptions)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .card-readmore {
        color: white;
        text-decoration: none;
        border-bottom: solid 1px white;
        display: inline-block;
        padding-bottom: 3px;
        line-height: 100%;
        svg {
            transform: translateX(-3px);
        }
        &:hover {
            opacity: 0.95;
            svg {
                transform: translateX(2px);
                transition: transform 250ms cubic-bezier(0, .52, 0, 1);
            }
        }
    }

    .card-editorial-title {
        color: white;
        text-decoration: none;
        font-weight: bold;
        font-size: ${props => props.theme.fontSizes[6]}px;
        line-height: 120%;
        margin-bottom: ${props => props.theme.space[3]}px;
        display: block;
    }

`;

const ReadabilityGradient = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 35.91%, rgba(0, 0, 0, 0.263793) 83.98%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
`

CardEditorialLarge.propTypes = {
    image: PropTypes.string.isRequired,
    locale: PropTypes.string,
    router: PropTypes.object,
    teaser: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default withLocale(CardEditorialLarge)